import { Table } from 'antd'

const TableBlock = ({
    columns,
    dataSource,
    rowKey = 'id',
    loading,
    size = 'middle',
    pageSize = 20,
}) => {
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            rowKey={rowKey}
            loading={loading}
            size={size}
            pagination={{
                defaultPageSize: pageSize,
                showSizeChanger: true
            }}
        />
    )
}

export default TableBlock