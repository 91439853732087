import { useContext } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Button, Table, Tag, Select } from 'antd'
import moment from 'moment/moment'
import FileSaver from 'file-saver'
import axios from 'axios'
import JSZip from 'jszip'

import { AppContext } from '../../context/AppContext'
import { getClaims, updateClaim } from '../../network/API'
import PageContent from '../../components/PageContent'
import Error from '../../components/Error'
import TableBlock from '../../components/TableBlock'

const Claims = () => {
    const { token } = useContext(AppContext)
    const navigate = useNavigate()
    const claims = useQuery(
        [
            'getClaims',
            {
                token: token?.token,
            },
        ],
        getClaims
    )

    const columns = [
        {
            title: 'Name',
            dataIndex: 'user',
            key: 'user',
            render: (entity) => entity?.name,
        },
        {
            title: 'Claim Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Total Price',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            render: (entity) => entity?.name,
        },
        {
            title: 'Submit Date',
            key: 'createdAt',
            render: (entity) => moment(entity.createdAt).format('DD/MM/YYYY'),
        },
        {
            title: 'Document',
            key: 'document',
            render: (entity) => (
                <div className='btn-box'>
                    <Button
                        type="link"
                        size='small'
                        onClick={() => handleDownload(entity)}
                    >
                        Download
                    </Button>
                </div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (entity) => (
                <Button
                    type="link"
                    size='small'
                    onClick={() => navigate(`/claims/${entity.id}`)}
                >
                    View claim
                </Button>
            )
        },
        {
            title: 'Status',
            key: 'status',
            render: (entity) => (
                <Status
                    status={entity.status}
                    fieldKey={'status'}
                    keyRequest={[
                        'getClaims',
                        {
                            token: token.token,
                        },
                    ]}
                    editRequest={updateClaim}
                    id={entity.id}
                    config={[
                        {
                            key: 'approved',
                            label: 'Approved',
                            color: 'green'
                        },
                        {
                            key: 'pending',
                            label: 'Pending',
                            color: 'orange'
                        },
                        {
                            key: 'rejected',
                            label: 'Rejected',
                            color: 'red'
                        },
                        {
                            key: 'closed',
                            label: 'Closed',
                            color: 'cyan'
                        },
                    ]}
                />
            )
        },
    ]

    const getFile = (url) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                responseType: 'blob'
            }).then(data => {
                resolve(data.data)
            }).catch(error => {
                reject(error.toString())
            })
        })
    }

    const handleDownload = (entity) => {
        const zip = new JSZip()
        const promises = []
        entity?.claimDetails?.forEach(item => {
            const url = `${process.env.REACT_APP_API_URL}/storage/fapiao/${item?.file?.uri}`
            const promise = getFile(url).then(data => {
                zip.file(item?.file?.uri, data, { binary: true })
            })
            promises.push(promise)
        })
        Promise.all(promises).then(() => {
            zip.generateAsync({ type: 'blob' }).then(content => {
                FileSaver.saveAs(content, `${entity.user.name}-${entity.name}.zip`)
            })
        })
    }

    const exportList = () => {
        let str = 'Username,Claim Name,Total Price,Submit Date,Status,Transaction Date,Type,Price,Description'

        for (const claim of claims.data) {
            str += '\n' + claim.user?.username + ',' +
                claim.name + ',' +
                claim.totalPrice + ',' +
                moment(claim.createdAt).format('DD/MM/YYYY') + ',' +
                claim.status + ','

            claim.claimDetails.map((details, index) => {
                if (index !== 0) {
                    str += '\n,,,,,'
                }

                str += moment(details?.date).format('DD/MM/YYYY') + ',' +
                    details?.type?.name + ',' +
                    details?.price + ',' +
                    details?.description?.replace(/\n|\r/g, ' ')
            })
        }

        let exportContent = '\uFEFF'
        let blob = new Blob([exportContent + str], {
            type: 'text/plain;charset=utf-8'
        })
        FileSaver.saveAs(blob, 'Claims.csv')
    }

    return (
        <PageContent
            title={'Claim list'}
            headerActions={[
                {
                    label: 'Export CSV',
                    onClick: exportList,
                }
            ]}
        >
            {claims.isError ? (
                <Error retry={() => claims.refetch()} />
            ) : (
                <TableBlock
                    columns={columns}
                    dataSource={claims.data}
                    loading={claims.isLoading}
                />
            )}
        </PageContent>
    )
}

const Status = ({
    status,
    id,
    config,
    fieldKey,
    keyRequest,
    editRequest,
}) => {
    const { token } = useContext(AppContext)
    const queryClient = useQueryClient()

    const badgeColor = (key) => config.find((e) => e.key === key)?.color || ''

    const mutation = useMutation((values) => editRequest(...values), {
        onSuccess: (data, variables) => {
            queryClient.setQueryData(keyRequest, (oldData) => {

                let oldDataIndex = oldData.findIndex(
                    (e) => e.id === data.id
                )
                oldData[oldDataIndex][fieldKey] = data[fieldKey]
                return oldData
            })
        },
    })

    return (
        <Select
            bordered={false}
            className="status-select"
            loading={mutation.isLoading}
            value={status}
            onChange={(e) => mutation.mutate([token.token, { [fieldKey]: e }, id])}
        >
            {config?.map((status) => (
                <Select.Option
                    disabled={status.disabled}
                    key={status.key}
                    value={status.key}
                >
                    <Tag
                        color={badgeColor(status.key)}
                        bordered={false}
                    >
                        {status.label}
                    </Tag>
                </Select.Option>
            ))}
        </Select>
    )
}

export default Claims