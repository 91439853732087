import { Button, Layout, Typography } from 'antd'

const PageContent = ({
    title,
    children,
    headerActions,
}) => {
    return (
        <Layout
            className="page-content-layout"
        >
            <Layout.Header
                style={{
                    padding: 0,
                    background: '#f5f5f5',
                }}
            >
                <Typography.Title
                    level={3}
                    style={{
                        margin: 0,
                    }}
                >
                    {title}
                </Typography.Title>
                {headerActions && (
                    <span>
                        {headerActions.map((action) => {
                            return (
                                <Button
                                    type='primary'
                                    onClick={action.onClick}
                                >
                                    {action.label}
                                </Button>
                            )
                        })}
                    </span>
                )}
            </Layout.Header>
            <Layout.Content>{children}</Layout.Content>
        </Layout>
    )
}

export default PageContent