import { Button, Space, Typography } from 'antd'
import { FrownOutlined } from '@ant-design/icons'

const Error = ({ retry }) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 200,
        }}
    >
        <Space
            direction="vertical"
            value="large"
            style={{ alignItems: 'center' }}
        >
            <FrownOutlined style={{ fontSize: '27px' }} />
            <Typography.Title level={3}>Oops something went wrong.</Typography.Title>
            <Button onClick={() => retry()} type="primary">
                Retry
            </Button>
        </Space>
    </div>
)

export default Error
