import { useContext } from 'react'
import { Button, Input, Form, Typography } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'

import { AppContext } from '../context/AppContext'
import { LOGIN } from '../reducers/Reducers'
import { TOKEN_SET } from '../reducers/Reducers'
import { login } from '../network/API'

import Logo from '../images/logo_full_navy.png'

const Login = () => {
    const { dispatchUser, dispatchToken } = useContext(AppContext)

    const mutation = useMutation(
        (values) => login(values.username, values.password),
        {
            onSuccess: (data, variables, context) => {
                dispatchToken({
                    type: TOKEN_SET,
                    payload: {
                        token: data.token,
                        expireAt: data.expireAt,
                    },
                })
                dispatchUser({
                    type: LOGIN,
                    payload: data.user,
                })
                localStorage.setItem('slingshotUser', JSON.stringify(data.user))
                localStorage.setItem(
                    'slingshotToken',
                    JSON.stringify({
                        token: data.token,
                        expireAt: data.expireAt,
                    })
                )
                localStorage.setItem(
                    'slingshotRefreshToken',
                    JSON.stringify({
                        token: data.refreshToken,
                        expireAt: data.refreshExpireAt,
                    })
                )
            },
            onError: (error, variables, context) => { },
        }
    )

    const onFinish = (values) => {
        mutation.mutate(values)
    }

    return (
        <div className='login-page'>
            <img className='login-logo' src={Logo} alt='logo' />
            <div className='login-container'>
                <h1>Manage Slingshot Expense.</h1>

                <Form className='login-form' name="login" onFinish={onFinish}>
                    <h2>Sign in</h2>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                type: 'username',
                                message: 'The input is not valid username!',
                            },
                            {
                                required: false,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input
                            prefix={
                                <UserOutlined />
                            }
                            placeholder='Username'
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input
                            prefix={
                                <LockOutlined />
                            }
                            type='password'
                            placeholder='Password'
                        />
                    </Form.Item>
                    {mutation.isError && (
                        <Typography.Text type="danger">Wrong credentials</Typography.Text>
                    )}
                    <Form.Item>
                        <Button type='primary' htmlType='submit' block loading={mutation.isLoading}>Sign in</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Login
